import type { GetServerSidePropsContext, NextPage } from "next";

import AppScreenshot from "@/components/site/home/AppScreenshot";
import BackgroundPattern from "@/components/site/BackgroundPattern";
import CTA from "@/components/site/home/CTA";
import Features from "@/components/site/home/Features";
import Footer from "@/components/site/Footer";
import Head from "next/head";
import Header from "@/components/site/Header";
import Hero from "@/components/site/home/Hero";
import LogoCloud from "@/components/site/home/LogoCloud";
import { redirectLoggedInUserToApp } from "@/utils/auth";

type Props = {};

const Home: NextPage<Props> = () => {
  return (
    <>
      <Head>
        <title>manmademammal</title>
        <meta name="description" content="welcome to mammal house" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div className="bg-gray-50">
        <div className="relative overflow-hidden">
          <BackgroundPattern />

          <div className="relative pt-6 pb-16 sm:pb-24">
            <Header />
            <Hero />
          </div>

          <AppScreenshot />
        </div>

        <LogoCloud />
      </div>

      <Features />
      <CTA />
      <Footer />
    </>
  );
};

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const shouldRedirect = await redirectLoggedInUserToApp(context);
  if (shouldRedirect) return shouldRedirect;

  return {
    props: {},
  };
};

export default Home;
