import Image from "next/image";
import appDemoImage from "public/images/app-demo.jpg";

export default function AppScreenshot() {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex flex-col" aria-hidden="true">
        <div className="flex-1" />

        <div className="flex-1 w-full bg-gray-800" />
      </div>

      <div className="px-4 mx-auto max-w-7xl sm:px-6">
        <Image
          className="relative rounded-lg shadow-lg"
          src={appDemoImage}
          alt="App screenshot"
          width={1200}
          height={850}
          placeholder="blur"
        />
      </div>
    </div>
  );
}
