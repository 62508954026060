import Image from "next/image";

export default function LogoCloud() {
  return (
    <div className="bg-gray-800">
      <div className="px-4 py-16 mx-auto max-w-7xl sm:py-24 sm:px-6 lg:px-8">
        <h2 className="text-sm font-semibold tracking-wide text-center text-gray-400 uppercase">
          Trusted by over 26,000 forward-thinking companies
        </h2>

        <div className="grid grid-cols-2 gap-8 mt-8 md:grid-cols-6 lg:grid-cols-5">
          <div className="flex justify-center col-span-1 md:col-span-2 lg:col-span-1">
            <Image
              className="h-12"
              src="https://tailwindui.com/img/logos/tuple-logo-gray-400.svg"
              alt="Tuple"
              height={48}
              width={200}
            />
          </div>

          <div className="flex justify-center col-span-1 md:col-span-2 lg:col-span-1">
            <Image
              className="h-12"
              src="https://tailwindui.com/img/logos/mirage-logo-gray-400.svg"
              alt="Mirage"
              height={48}
              width={200}
            />
          </div>

          <div className="flex justify-center col-span-1 md:col-span-2 lg:col-span-1">
            <Image
              className="h-12"
              src="https://tailwindui.com/img/logos/statickit-logo-gray-400.svg"
              alt="StaticKit"
              height={48}
              width={200}
            />
          </div>

          <div className="flex justify-center col-span-1 md:col-span-3 lg:col-span-1">
            <Image
              className="h-12"
              src="https://tailwindui.com/img/logos/transistor-logo-gray-400.svg"
              alt="Transistor"
              height={48}
              width={200}
            />
          </div>

          <div className="flex justify-center col-span-2 md:col-span-3 lg:col-span-1">
            <Image
              className="h-12"
              src="https://tailwindui.com/img/logos/workcation-logo-gray-400.svg"
              alt="Workcation"
              height={48}
              width={200}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
