export default function Hero() {
  return (
    <div className="px-4 mx-auto mt-16 max-w-7xl sm:mt-24 sm:px-6">
      <div className="text-center">
        <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
          <span className="block">Data to enrich your</span>

          <span className="block text-transparent bg-clip-text bg-gradient-to-br from-blue-400 to-indigo-600">
            online business
          </span>
        </h1>

        <p className="max-w-md mx-auto mt-3 text-base font-light text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.
          Elit sunt amet fugiat veniam occaecat fugiat aliqua.
        </p>
      </div>
    </div>
  );
}
