import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Popover, Transition } from "@headlessui/react";

import { Fragment } from "react";
import Image from "next/image";
import Link from "next/link";

const navigation = [
  { name: "Solutions", href: "/solutions" },
  { name: "Pricing", href: "/pricing" },
  { name: "Company", href: "/company" },
  { name: "Blog", href: "/blog" },
];

export default function Header() {
  return (
    <Popover>
      {/* Large screen nav */}
      <div className="px-4 mx-auto max-w-7xl sm:px-6">
        <nav
          className="relative flex items-center justify-between sm:h-10 md:justify-center"
          aria-label="Global"
        >
          <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <Link href="/">
                <a>
                  <span className="sr-only">Workflow</span>

                  <Image
                    className="w-auto h-8 sm:h-10"
                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                    alt=""
                    width={44}
                    height={40}
                  />
                </a>
              </Link>

              <div className="flex items-center -mr-2 md:hidden">
                <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md bg-gray-50 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>

                  <MenuIcon className="w-6 h-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
          </div>

          {/* Large screen nav */}
          <div className="hidden md:flex md:space-x-10">
            {navigation.map((item) => (
              <Link key={item.name} href={item.href}>
                <a className="font-normal text-gray-500 hover:text-gray-900">{item.name}</a>
              </Link>
            ))}
          </div>

          <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
            <span className="inline-flex">
              <Link href="/login">
                <a className="inline-flex items-center text-base font-normal text-gray-500 hover:text-gray-900">
                  Login
                </a>
              </Link>
            </span>

            <span className="inline-flex ml-8 rounded-md shadow">
              <Link href="/signup">
                <a className="inline-flex items-center px-4 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700">
                  Sign Up
                </a>
              </Link>
            </span>
          </div>
        </nav>
      </div>

      {/* Small screen header */}
      <Transition
        as={Fragment}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute inset-x-0 top-0 z-10 p-2 transition origin-top-right transform md:hidden"
        >
          <div className="overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5">
            {/* Small screen logo and menu controls */}
            <div className="flex items-center justify-between px-5 pt-4">
              <div>
                <Image
                  className="w-auto h-8"
                  src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                  alt=""
                  layout="fill"
                />
              </div>

              <div className="-mr-2">
                <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Close main menu</span>

                  <XIcon className="w-6 h-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>

            {/* Small screen nav */}
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Link key={item.name} href={item.href}>
                  <a className="block px-3 py-2 text-base font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50">
                    {item.name}
                  </a>
                </Link>
              ))}
            </div>

            {/* Small screen login */}
            <Link href="/login">
              <a className="block w-full px-5 py-3 font-medium text-center text-indigo-600 bg-gray-50 hover:bg-gray-100 hover:text-indigo-700">
                Log in
              </a>
            </Link>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
